<template>
  <div class="auto-card" :class="cssClasses">
    <div class="auto-card__plate-number">
      <img
        :src="`/images/flags/${autoInfo.country_registration.iso}.svg`"
        alt=""
        class="auto-card__country-icon"
      />
      {{ autoInfo.auto_registration_plate }}
    </div>

    <svg-icon
      v-if="isProblemBufferZoneStatus"
      name="broken-auto"
      class="auto-card__icon-problem-status"
    ></svg-icon>
    <svg-icon
      v-if="isTurnHomeStatus"
      name="to-home-status"
      class="auto-card__icon-problem-status"
    ></svg-icon>

    <div class="auto-card__row">
      <div class="auto-card__key">{{ $t('dashboard.brand') }}</div>
      <div class="auto-card__values">
        {{ autoInfo.car_brand.title }}
      </div>
    </div>

    <div class="auto-card__row">
      <div class="auto-card__key">{{ $t('dashboard.model') }}</div>
      <div class="auto-card__values">{{ autoInfo.car_model.title }}</div>
    </div>

    <div class="auto-card__row">
      <div class="auto-card__key">{{ $t('dashboard.passengers') }}</div>
      <div class="auto-card__values">
        {{ autoInfo.count_of_passengers }}
      </div>
    </div>

    <div class="auto-card__row">
      <TypeAuto :type="autoInfo.type_auto" />
    </div>
    <div v-if="checksCount" class="auto-card__status">
      <div
        v-for="item in checksCount"
        :key="item"
        class="auto-card__check-item"
      >
        <svg-icon name="auto-card-check"></svg-icon>
      </div>
    </div>
  </div>
</template>

<script>
import TypeAuto from '@/elements/TypeAuto/TypeAuto.vue';

export default {
  components: {
    TypeAuto,
  },
  props: {
    autoInfo: {
      type: Object,
      required: true,
    },
    checksCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    cssClasses() {
      return {
        'auto-card--custom-status': this.isInCustomsZoneStatus,
        'auto-card--problem-status': this.isProblemBufferZoneStatus,
        'auto-card--turn-home-status': this.isTurnHomeStatus,
      };
    },
    isInCustomsZoneStatus() {
      return this.autoInfo.status.includes('customs');
    },
    isProblemBufferZoneStatus() {
      return this.autoInfo.status === 'problem_buffer_zone';
    },
    isTurnHomeStatus() {
      return this.autoInfo.status.includes('turn_home');
    },
  },
  methods: {},
};
</script>
<style lang="sass" scoped>
.auto-card
  position: relative
  display: flex
  flex-direction: column
  padding: 25px 25px 25px 20px
  border: 1px solid rgba(40, 59, 89, 0.3)
  border-radius: 4px
  box-shadow: 0px 32px 80px rgba(38, 55, 76, 0.05)
  cursor: pointer

.auto-card__row
  display: flex
  align-items: center
  gap: 8px

.auto-card__row+.auto-card__row
  margin-top: 8px

.auto-card__plate-number
  display: flex
  align-items: center
  margin-bottom: 16px
  font-size: 24px
  font-weight: 600
  color: var(--primary-color-dark-blue)

.auto-card--turn-home-status,
.auto-card--problem-status
  border: 1px solid rgba(255, 102, 102, 0.5)

.auto-card--custom-status .auto-card__plate-number
  color: var(--btn-color-blue)

.auto-card--turn-home-status .auto-card__plate-number,
.auto-card--problem-status .auto-card__plate-number
  color: var(--secondary-color-red-1)

.auto-card__icon-problem-status
  position: absolute
  right: 20px
  top: 20px
  width: 35px
  height: 32px

.auto-card__country-icon
  flex: 0 0 32px
  width: 32px
  height: 22px
  margin-right: 12px

.auto-card__key
  flex: 0 0 80px
  color: var(--primary-color-dark-grey-1)
  font-size: 16px

.auto-card__values
  justify-items: flex-start
  align-self: flex-start
  font-size: 18px
  font-weight: 600
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.auto-card .type-auto
  height: 48px
  margin-top: 16px

.auto-card__status
  position: absolute
  top: -12px
  right: -12px
  display: flex
  align-items: center
  gap: 0 8px
  font-size: 0
</style>
