<template>
  <div class="dashboard">
    <component
      :is="currentComponent"
      :broadcast="broadcast"
      :role="currentRole"
    ></component>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import DashboardBarrier from '@/components/DashboardRole/DashboardBarrier.vue';
import DashboardCustomsControl from '@/components/DashboardRole/DashboardCustomsControl.vue';

export default {
  components: {
    DashboardBarrier,
    DashboardCustomsControl,
  },
  data() {
    return {
      broadcast: null,
      timerId: null,
    };
  },

  computed: {
    ...mapGetters(['getUser']),
    currentRole() {
      return this.getUser?.role || '';
    },
    currentComponent() {
      return this.currentRole === 'barrier'
        ? 'DashboardBarrier'
        : 'DashboardCustomsControl';
    },
  },

  mounted() {
    const broadcastType =
      this.currentRole === 'barrier'
        ? 'getBarrierBroadcast'
        : 'getCustomsControlBroadcast';
    this.subscribeToBroadcast(broadcastType);
  },

  destroyed() {
    clearInterval(this.timerId);
  },

  methods: {
    subscribeToBroadcast(broadcastType) {
      this.timerId = setInterval(async () => {
        const res = await this.$store.dispatch(broadcastType);
        this.broadcast = res;
      }, 1000);
    },
  },
};
</script>

<style lang="sass">
.list-of-autos .loader
  display: flex
  min-height: 243px
</style>
