<template>
  <div class="change-auto__current-auto">
    <div class="change-auto__plate-number">
      <img
        :src="`/images/flags/${autoInfo.country_registration.iso}.svg`"
        alt=""
        class="change-auto__plate-number-icon"
      />
      <span>{{ autoInfo.auto_registration_plate }}</span>
    </div>
    <div class="change-auto__titles">
      {{ $t('dashboard.brand') }}
      <br />
      <span class="change-auto__values"> {{ autoInfo.car_brand.title }} </span>
    </div>
    <div class="change-auto__titles">
      {{ $t('dashboard.model') }}
      <br />
      <span class="change-auto__values"> {{ autoInfo.car_model.title }} </span>
    </div>
    <div class="change-auto__titles">
      {{ $t('dashboard.passengers') }}
      <br />
      <span class="change-auto__values">
        {{ autoInfo.count_of_passengers }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    autoInfo: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="sass" scoped>
.change-auto__current-auto
  display: grid
  grid-template-columns: 2.3fr 2.5fr 1fr
  grid-template-rows: 0fr 1fr
  height: 155px
  padding: 25px
  border: 1px solid var(--primary-color-dark-grey-4)
  border-radius: 4px
  margin-bottom: 48px

.change-auto__plate-number
  grid-column: 1 / -1
  display: flex
  align-items: center

.change-auto__plate-number-icon
  height: 27px
  width: 40px
  margin-right: 16px

.change-auto__plate-number span
  font-size: 32px
  font-weight: 600
  color: var(--primary-color-dark-blue)

.change-auto__titles
  text-align: left
  justify-self: start
  font-size: 16px
  line-height: 25px
  color: var(--primary-color-dark-grey-1)
  align-self: flex-end

.change-auto__values
  font-size: 18px
  font-weight: 600
  color: var(--primary-color-dark-blue)
</style>
