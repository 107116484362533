<template>
  <div class="auto-card auto-card--no-active">
    <div class="auto-card__icon">
      <svg-icon name="nocar"></svg-icon>
    </div>
    <div class="auto-card__text">{{ cardText }}</div>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    cardText() {
      return this.status === 'pending-auto'
        ? this.$t('active_zone.pending_auto')
        : this.$t('active_zone.no_active_auto');
    },
  },
};
</script>
<style lang="sass" scoped>
.auto-card
  position: relative
  display: flex
  flex-direction: column
  padding: 25px 25px 25px 20px
  border: 1px solid rgba(40, 59, 89, 0.3)
  border-radius: 4px
  box-shadow: 0px 32px 80px rgba(38, 55, 76, 0.05)
  cursor: pointer

.auto-card--no-active
  align-items: center
  justify-content: center
  min-height: 245px

  .icon-nocar
    width: 38px
    height: 40px

  .auto-card__text
    max-width: 150px
    font-size: 24px
    line-height: 32px
    text-align: center
    color: var(--primary-color-dark-grey-5)
    margin-top: 6px
</style>
