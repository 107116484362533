<template>
  <div class="list-of-autos">
    <AutosZone v-for="zone in autoZonesList" :key="zone.name" :name="zone.name">
      <Heading level="4">{{
        zone.name === 'active-zone'
          ? $t('active_zone.active_auto')
          : $t('dashboard.in_boarder_zone')
      }}</Heading>
      <Loader v-if="!zone.autos" />
      <template v-else>
        <div class="autos-zone__cards">
          <AutoCardEmpty
            v-if="!zone.autos.length"
            :status="
              zone.name === 'active-zone' ? 'no-active-auto' : 'pending-auto'
            "
          />
          <AutoCard
            v-for="item in zone.autos"
            v-else
            :key="item.auto_registration_plate"
            :auto-info="item"
            :checks-count="checkCount"
            @click.native="chooseAutoHandler(item)"
          />
        </div>
      </template>
    </AutosZone>

    <Popup :dialog.sync="modalTakeInWork">
      <Heading level="4" class="popup-choosen-auto__title">
        {{ $t('custom_zone.take_to_active') }}
      </Heading>
      <div class="popup__subtitle">
        <ChoosenAuto :auto-info="choosenAuto"></ChoosenAuto>
      </div>
      <div class="popup__btns">
        <ButtonBase
          size="md-bg"
          color="white"
          @click.native="closeModalTakeInWork"
          >{{ $t('custom_zone.no') }}</ButtonBase
        >
        <ButtonBase
          size="md-bg"
          color="blue"
          @click.native="takeInWorkTicketHandler"
        >
          <template v-if="!loadingTakeInWorkBtn">
            {{ $t('custom_zone.take') }}
          </template>
          <Loader v-else color="white" />
        </ButtonBase>
      </div>
    </Popup>

    <Popup :dialog.sync="modalApproveCheck" is-persistent>
      <Heading
        level="4"
        class="popup-choosen-auto__title popup-choosen-auto__title--lg"
      >
        {{ $t('custom_zone.approve_check_title') }}
      </Heading>
      <div class="popup__subtitle">
        <ChoosenAuto :auto-info="choosenAuto"></ChoosenAuto>
      </div>
      <div class="popup__btns">
        <ButtonBase
          size="md-bg"
          color="red"
          @click.native="declineTicketHandler"
        >
          <template v-if="!loadingDeclineBtn">
            {{ $t('custom_zone.denied') }}
          </template>
          <Loader v-else color="red" />
        </ButtonBase>
        <ButtonBase
          size="md-bg"
          color="blue"
          @click.native="approveTicketHandler"
        >
          <template v-if="!loadingApproveBtn">
            {{ approveBtnText }}
          </template>
          <Loader v-else color="white" />
        </ButtonBase>
      </div>
      <button
        type="button"
        class="popup__close-btn"
        @click="closeModalApproveCheck"
      >
        <svg-icon name="popup-close"></svg-icon>
      </button>
    </Popup>
  </div>
</template>

<script>
import Popup from '@/elements/Popup/Popup.vue';
import Heading from '@/elements/Heading/Heading.vue';
import ButtonBase from '@/elements/Buttons/ButtonBase.vue';
import Loader from '@/elements/Loader/Loader.vue';

import AutosZone from '@/components/AutosZone/AutosZone.vue';
import ChoosenAuto from '@/components/ChoosenAuto/ChoosenAuto.vue';
import AutoCard from '@/components/Auto/AutoCard.vue';
import AutoCardEmpty from '@/components/Auto/AutoCardEmpty.vue';
export default {
  components: {
    AutoCard,
    Heading,
    Popup,
    Loader,
    AutosZone,
    ChoosenAuto,
    ButtonBase,
    AutoCardEmpty,
  },
  props: {
    broadcast: {
      required: true,
      validator: (prop) => typeof prop === 'object' || prop === null,
    },
    role: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      choosenAuto: null,

      modalTakeInWork: false,
      modalApproveCheck: false,

      loadingTakeInWorkBtn: false,
      loadingDeclineBtn: false,
      loadingApproveBtn: false,

      customsControlChecksCount: {
        'customs-control-first': 1,
        'customs-control-second': 2,
        'customs-control-third': 3,
      },
    };
  },
  computed: {
    autoZonesList() {
      return [
        {
          name: 'active-zone',
          autos: this.broadcast?.active,
        },
        {
          name: 'waiting-zone',
          autos: this.broadcast?.waiting,
        },
      ];
    },
    activeAutos() {
      return this.broadcast?.active;
    },
    waitingAutos() {
      return this.broadcast?.waiting;
    },
    checkCount() {
      return this.customsControlChecksCount[this.role];
    },
    approveBtnText() {
      return this.role === 'customs-control-third'
        ? this.$t('custom_zone.left_territory')
        : this.$t('custom_zone.approve');
    },
  },
  methods: {
    openModalTakeInWork() {
      this.modalTakeInWork = true;
    },
    closeModalTakeInWork() {
      this.modalTakeInWork = false;
    },
    openModalApproveCheck() {
      this.modalApproveCheck = true;
    },
    closeModalApproveCheck() {
      this.modalApproveCheck = false;
    },
    setChoosenAuto(auto) {
      this.choosenAuto = auto;
    },
    chooseAutoHandler(item) {
      this.setChoosenAuto(item);

      item.status === 'in_customs_zone' ||
      item.status.includes('success_customs_control')
        ? this.openModalTakeInWork()
        : this.openModalApproveCheck();
    },
    async takeInWorkTicketHandler() {
      this.loadingTakeInWorkBtn = true;

      await this.$store.dispatch(
        'takeInWorkTicketCustomsControl',
        this.choosenAuto?.uuid
      );

      this.loadingTakeInWorkBtn = false;
      this.closeModalTakeInWork();
    },

    async declineTicketHandler() {
      this.loadingDeclineBtn = true;

      await this.$store.dispatch(
        'declineTicketCustomsControl',
        this.choosenAuto?.uuid
      );

      this.loadingDeclineBtn = false;
      this.closeModalApproveCheck();
    },

    async approveTicketHandler() {
      this.loadingApproveBtn = true;

      await this.$store.dispatch(
        'approveTicketCustomsControl',
        this.choosenAuto?.uuid
      );

      this.loadingApproveBtn = false;
      this.closeModalApproveCheck();
    },
  },
};
</script>
<style lang="sass" scoped></style>
