<template>
  <div
    class="type-auto"
    :class="{
      simple: type === 'simple_truck',
      flammable: type === 'flammable_truck',
      perishable: type === 'perishable_truck',
    }"
  >
    {{ $t(`type_auto.${type}`) }}
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="sass" scoped>
.type-auto
  display: flex
  align-items: center
  justify-content: center
  height: 38px
  width: 100%
  font-size: 20px
  font-weight: 600

.type-auto.simple
  background-color: rgba(40, 59, 89, 0.06)
  color: var(--primary-color-dark-blue)

.type-auto.flammable
  background-color: rgba(255, 0, 0, 0.06)
  color: #ff0000

.type-auto.perishable
  background-color: rgba(255, 121, 23, 0.06)
  color: var(--primary-color-wasty)
</style>
