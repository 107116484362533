<template>
  <div class="autos-zone" :class="name">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="sass" scoped>
.pending-zone,
.active-zone
  border-bottom: 1px solid var(--primary-color-light-grey-2)
  padding-bottom: 48px

.in-customs-zone,
.waiting-zone
  margin-bottom: 70px

.autos-zone__cards
  display: grid
  grid-template-columns: repeat(3, 1fr)
  gap: 20px
  min-height: 243px

  @media screen and (min-width: 1200px)
    grid-template-columns: repeat(4, 1fr)
</style>
